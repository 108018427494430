// https://colordesigner.io/gradient-generator
export function hexToRGBA(hex: string, alpha: number) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${r},${g},${b},${alpha})`;
}

export enum ColorsEnum {
  g = "#009C00",
  downHint = "#EF2B00",
  primary = "#ff9b00",
  secondary = "#FFBAAB",
  success = "#85B899",
  beer = "#FF9B00",
  beer0 = "#171717",
  beer1 = "#171717",
  beer2 = "#171717",
  beer3 = "#171717",
  beer4 = "#171717",
  beer5 = "#737373",
  beer6 = "#c5a07d",
  beer7 = "#cea072",
  beer8 = "#d29f6d",
  beer9 = "#d69f67",
  beer10 = "#da9f61",
  beer11 = "#de9e5b",
  beer12 = "#e19e56",
  beer13 = "#e59e4f",
  beer14 = "#e89d49",
  beer15 = "#ef9d3c",
  beer16 = "#f29c34",
  beer17 = "#fc9b15",
  beer18 = "#ff9b00",
  beer19 = "#2b2b2b",
  beer20 = "#252525",
  beer21 = "#1f1f1f",
  beer22 = "#191919",
  beer23 = "#171717",
  error = "#F5734D",
  sunshine = "#FFDA00",
  royalred = "#7D001C",
  tangerine = "#FF5208",
  tangerine0 = "#DA4606",
  tangerine1 = "#FF6A2B",
  tangerine2 = "#FF834E",
  tangerine3 = "#FF9C71",
  tangerine4 = "#FFB495",
  tangerine5 = "#FFCDB8",
  salmon = "#F5734D",
  blush = "#FFBAAB",
  bone = "#FFEDDE",
  bone1 = "#FFF6EE",
  lavender = "#A37DFC",
  amethyst = "#6100FA",
  field = "#85B899",
  mint = "#85E8AD",
  azure = "#DBFAFA",
  azure1 = "#EDFCFC",
  gunmetal = "#333333",
  black = "#000000",
  grey = "#808080",
  warmgray1 = "#55524F",
  warmgray2 = "#77716C",
  warmgray3 = "#999088",
  warmgray4 = "#BBAFA5",
  warmgray5 = "#DDCEC1",
  warmgray6 = "#202020",
  coolgray1 = "#4F5454",
  coolgray2 = "#6B7575",
  coolgray3 = "#879696",
  coolgray4 = "#A3B7B7",
  coolgray5 = "#edebeb",
  coolgray6 = "#e6e6ed",
  coolgray7 = "#fbfaff",
  coolgray8 = "#383838",
  lightGrey = "#F4F4F4",
  white = "#FFFFFF",
  offWhite = "#F5F5F5",
  teal = "#008080",
  mamitaGreen = "#26D221",
  darkGreen = "#006100",
  geekBlue = "#3D5884",
  intellectualLavender = "#774161",
  teenPurple = "#8A02E5",
  mainstreamBlue = "#00D2FF",
  machoBlue = "#4169E1",
  lighterMachoBlue = "#80A0FF",
  seeAndBeGreen = "#00C89B",
  memeBlue = "#76ABFF",
  youthPink = "#FF00FF",
  gamerKhaki = "#C8BFA8",
  oldschoolOrange = "#F3790D",
  facebook = "#4267B2",
  rain = "#82B3ED",
  twitter = "#1DA1F2",
  red = "#FF0000",
  yellow = "#FFCC00",
  green = "#37A63A",
  instagram = "#C13584",
  darkGrey = "#2b2b2b",
  darkerGrey = "#0E0E0E",
  darkMode = "#121212",
  economicBlue = "#01253F",
  limeGreen = "#E4F5D5",
  lightLime = "#F9FFF2",
  coffee = "#6F4E37",
}
