// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/caros/cretype  Caros.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Caros";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  background-color: #f8f7f1;
  font-family: "Caros", sans-serif;
  font-weight: 400; /* or other available weight */
  overflow-x: hidden;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,+DAAwE;EACxE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,yBAAyB;EACzB,gCAAgC;EAChC,gBAAgB,EAAE,8BAA8B;EAChD,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":["@font-face {\n  font-family: \"Caros\";\n  src: url(\"./assets/fonts/caros/cretype\\ \\ Caros.otf\") format(\"opentype\");\n  font-weight: normal;\n  font-style: italic;\n}\n\nbody {\n  margin: 0;\n  background-color: #f8f7f1;\n  font-family: \"Caros\", sans-serif;\n  font-weight: 400; /* or other available weight */\n  overflow-x: hidden;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
